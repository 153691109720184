const Footer = () => {
	return (
		<>
		<div className="container py-2 pt-4 mt-auto">
			<p>Made By <a href="https://www.hexhowells.com">HexHowells</a> with 💙</p>
		</div>
		</>
		);
};

export default Footer;

